import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root',
})
export class HomeServiceService {
  private s3: AWS.S3;
  private id: string | null = null;
  private idSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private pharmacyIdSubject: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(
    Number(this.cookieService.get('pharmacyId')) || null,
  );

  private pharmacyDataSubject = new BehaviorSubject<any>(null);
  pharmacyData$ = this.pharmacyDataSubject.asObservable();
  pharmacyIdSubjectData$ = this.pharmacyIdSubject.asObservable();
  private addressSubject = new BehaviorSubject<{ lat: number; lng: number } | null>(null);

  private commercialName!: string;
  private parmacyId: any;
  private catName!: string;
  private subCatName!: string;
  private subCatId: any;
  private likeCounterSource = new BehaviorSubject<number>(0);
  likeCounter$ = this.likeCounterSource.asObservable();
  private selectedCategory: any;

  updateLikeCounter(count: number) {
    this.likeCounterSource.next(count);
  }
  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      let route = this.activatedRoute.root;
      while (route.firstChild) {
        route = route.firstChild;
      }
      route.params.subscribe(params => {
        this.id = params['pharmacieId'] || null;
        this.idSubject.next(this.id);
        console.log('pharmacieId from service', this.id);
      });
    });
    AWS.config.update({
      accessKeyId: '4490a585d93345348725fc091e1bdcd2',
      secretAccessKey: 'd094bb9ad86c412dac93ce699e7807aa',
      region: 'sbg',
    });

    this.s3 = new AWS.S3({
      endpoint: 'https://s3.sbg.io.cloud.ovh.net/',
      s3ForcePathStyle: true, // Nécessaire pour utiliser l'API S3 d'OVH
    });
  }
  getPharmacy(page: number, size: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/pharmacies?page=${page}&size=${size}`);
  }
  uploadFiles(purchaseId: any, formData: FormData): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/files/upload/${purchaseId}/all`, formData);
  }

  getFileUrl(purchaseId: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/files/${purchaseId}/documents/all`);
  }
  getImage(bucketName: any, key: any): Promise<any> {
    const params = {
      Bucket: bucketName,
      Key: key,
    };

    return this.s3.getObject(params).promise();
  }

  searchPharmacies(searchkeys: any) {
    return this.http.get(`${environment.baseUrl}api/pharmacies/search?searchkeys=${searchkeys}`);
  }
  getPharmacyById(pharmacyId: string): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/pharmacies/${pharmacyId}`);
  }
  searchPharmaciesWithLatLang(lat: any, lng: any, page: any, addressId: any) {
    return this.http.get(`${environment.baseUrl}api/pharmacies/nearby?lat=${lat}&lng=${lng}&page=${page}&addressId=${addressId}`);
  }
  getCategories(pharmacyType: any, pharmacyId: any, page: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/categories?type=${pharmacyType}&pharmacyId=${pharmacyId}&page=${page}`);
  }
  getPromos(pharmacyId: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/stock-items/promos?pharmacyId=${pharmacyId}`);
  }
  getSubCategories(categorie_id: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/categories/${categorie_id}/sub_categories`);
  }
  getStockItems(categorie_id: number, pharmacyId: number, page: number, size: number): Observable<any> {
    return this.http.get(
      `${environment.baseUrl}api/sub-categories/${categorie_id}/stock-items/pharmcy/${pharmacyId}?page=${page}&size=${size}`,
    );
  }
  likePharmacy(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/favoris`, data);
  }
  getFavorisPharmacy(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/favoris?entity=Pharmacy`);
  }
  likeProduct(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/favoris`, data);
  }
  getFavorisProduct(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/favoris?entity=StockItem`);
  }
  unlikeProduct(data: any): Observable<any> {
    const options = {
      body: data,
    };
    return this.http.request<any>('DELETE', `${environment.baseUrl}api/favoris`, options);
  }
  unlikePharmacy(data: any): Observable<any> {
    const options = {
      body: data,
    };
    return this.http.request<any>('DELETE', `${environment.baseUrl}api/favoris`, options);
  }
  getIdObservable() {
    return this.idSubject.asObservable();
  }
  addToCart(cartData: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/purchases`, cartData);
  }
  putCart(cartData: any, purchase_id: any): Observable<any> {
    return this.http.put<any>(`${environment.baseUrl}api/purchases/${purchase_id}`, cartData);
  }
  addPurchases(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/purchases/files`, data);
  }

  setCommercialName(name: string) {
    this.commercialName = name;
    this.cookieService.set('commercialName', name);
  }
  getCommercialName(): string {
    return this.cookieService.get('commercialName') || this.commercialName;
  }
  setPharmacyId(pharmacyId: any) {
    this.parmacyId = pharmacyId;
    this.pharmacyIdSubject.next(this.parmacyId);
    this.cookieService.set('pharmacyId', pharmacyId);
  }
  getPharmacyId(): string {
    return this.cookieService.get('pharmacyId') || this.parmacyId;
  }

  setCatName(name: string) {
    this.catName = name;
    this.cookieService.set('catName', name);
  }

  getCatName(): string {
    return this.cookieService.get('catName') || this.catName;
  }

  setSubCatName(name: string) {
    this.subCatName = name;
    this.cookieService.set('subCatName', name);
  }
  setSubCatId(id: any) {
    this.subCatId = id;
    this.cookieService.set('subCatId', id);
  }
  getSubCatName(): string {
    return this.cookieService.get('subCatName') || this.subCatName;
  }
  getSubCatId(): string {
    return this.cookieService.get('subCatId') || this.subCatId;
  }
  searchProduct(keyword: any, pharmacyId: any, subCategoryId: any): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/stock-items/search?keyword=${keyword}&pharmacyId=${pharmacyId}&subCategoryId=${subCategoryId}`,
    );
  }
  searchPurchsesPharmacist(keyword: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/purchases/search?keyword=${keyword}`);
  }
  searchStockPharmacist(keyword: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/stock-items/pharmacist-search?keyword=${keyword}`);
  }
  setSelectedCategory(category: any): void {
    this.selectedCategory = category;
  }
  getSelectedCategory(): any {
    return this.selectedCategory;
  }
  addAdresse(data: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/addresses`, data);
  }
  updateAdresse(data: any, addressId: any): Observable<any> {
    return this.http.patch<any>(`${environment.baseUrl}api/addresses/${addressId}`, data);
  }
  getAdresse(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/addresses`);
  }
  saveAdress(addressId: any, body: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}api/addresses/${addressId}/setlastused`, body);
  }
  deleteAdress(addressId: any) {
    return this.http.delete(`${environment.baseUrl}api/addresses/${addressId}`);
  }

  setAddressCoordinates(lat: number, lng: number) {
    this.addressSubject.next({ lat, lng });
  }

  getAddressCoordinates() {
    return this.addressSubject.asObservable();
  }
  getListStockItems(page: any): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/stock-items?page=${page}`);
  }
  uploadStockItems(file: FormData) {
    return this.http.post(`${environment.baseUrl}api/stock-items/upload-csv`, file);
  }
  getContactInfo(purchaseId: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/purchases/${purchaseId}/contact`);
  }

  updateContactInfo(purchaseId: number, contactInfo: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/purchases/${purchaseId}/contact`, contactInfo);
  }
  updateStockItem(data: any): Observable<any> {
    return this.http.patch(`${environment.baseUrl}api/stock-items/${data.id}`, data);
  }
}
