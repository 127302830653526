export const environment = {
  production: false,
  baseUrlImage: 'http://test.pharma-go.fr/file/..',
  baseUrl: 'https://test.pharma-go.fr/',
  googleMapsApiKey: 'AIzaSyCRcEr5d8ePuI2b8V3MPFCLSrMDC1hg-z0',
  firebase: {
    apiKey: 'AIzaSyBhE5Yxyu1UIlOZ_zE5V5myQ3SQlsQzmB8',
    authDomain: 'pharmago-c0a58.firebaseapp.com',
    projectId: 'pharmago-c0a58',
    storageBucket: 'pharmago-c0a58.appspot.com',
    messagingSenderId: '392409823744',
    appId: '1:392409823744:web:9241439f50c20a7412f6e5',
    measurementId: 'G-P798YF9VB7',
    vapidKey: 'BArGAWt5xpJy_bVVizRsy2VsH700HlbLzqSdbfXu61vEw5CKeibe9TXbba9HoDApSrMXu8rnE9dEI2UPLJwDAmg',
    // apiKey: "AIzaSyBi91ZOtN0xghHqbqXi1JgmnrBmSktYyv4",
    // authDomain: "notification-test-aed90.firebaseapp.com",
    // projectId: "notification-test-aed90",
    // storageBucket: "notification-test-aed90.appspot.com",
    // messagingSenderId: "1059926788404",
    // appId: "1:1059926788404:web:4b926e8eb5d39242b0fd61",
    // measurementId: "G-XPDL6VBSMT",
    // vapidKey: 'BKCqCUtJnEcquIN07VZEvXZ09t6gVF9EVgNBut-3E2zttKnsgfb2ixkCHBGCx2zqmdrTiBM5skvk0ZL8rOaEnO8',
  },
};
